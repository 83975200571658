<script
    lang="ts"
    setup
>
    import { useLanguageStore } from '~/stores/language'

    type Props = {
        up?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        up: false,
    })

    const tooltipRef = ref<ReturnType<typeof defineComponent>>()

    const { mobile } = useWindowSize()
    const languageStore = useLanguageStore()

    const icons = {
        uk: resolveComponent('AppIconFlagUK'),
        en: resolveComponent('AppIconFlagEN'),
        ru: resolveComponent('AppIconFlagRU'),
    }

    const tooltipProps = computed<any>(() => ({
        direction: props.up ? 'up' : 'down',
    }))

    onMounted(() => {
        if ((languageStore.language === 'ru') && isUserUkrainian() && !mobile.value) {
            tooltipRef.value?.open()
        }
    })
</script>

<template>
    <AppDropdown
        width="auto"
        offset-y="8"
        :from-bottom="props.up"
    >
        <template #activator="{ active, toggle }">
            <AppTooltip
                ref="tooltipRef"
                v-bind="tooltipProps"
                width="240"
                close-on-click-outside
                pointer-events="auto"
                :disabled="mobile"
            >
                <template #activator>
                    <AppDropdownItem
                        :active="active"
                        class="flex items-center !font-medium !mb-0"
                        @click="toggle"
                    >
                        <Component
                            :is="icons[languageStore.locale.iso]"
                            class="mr-2"
                        />

                        {{ languageStore.locale.name }}

                        <AppIconChevronDown
                            size="16"
                            :rotate="props.up ? !active : active"
                            :color="active ? '#000' : undefined"
                            class="ml-[4px]"
                        />
                    </AppDropdownItem>
                </template>

                <template #default="{ close: closeTooltip }">
                    <div class="flex flex-col items-center gap-2">
                        <div class="flex justify-center mt-4">
                            <AppIconFlagUK size="34" />
                        </div>

                        <div class="my-2 font-medium leading-[130%]">
                            {{ $t('switch-to-ukrainian-1') }}
                        </div>

                        <AppLink
                            class="w-full"
                            :to="{ params: { ...$route.params, language: 'uk' } }"
                            @click="languageStore.setCookieLanguage('uk'); closeTooltip()"
                        >
                            <AppButton class="w-full">
                                {{ $t('switch-to-ukrainian-2') }}
                            </AppButton>
                        </AppLink>

                        <AppButton
                            secondary
                            class="w-full"
                            @click="closeTooltip()"
                        >
                            {{ $t('switch-to-ukrainian-3') }}
                        </AppButton>
                    </div>
                </template>
            </AppTooltip>
        </template>

        <template #default="{ close }">
            <div class="flex flex-col gap-1">
                <AppLink
                    v-for="option in languageStore.languageOptions"
                    :key="option.value"
                    :to="{ params: { ...$route.params, language: option.value } }"
                    @click="languageStore.setCookieLanguage(option.value); close()"
                >
                    <AppDropdownItem :active="languageStore.language === option.value">
                        <div class="flex items-center gap-2 whitespace-nowrap text-[14px] font-medium">
                            <Component
                                :is="icons[languageStore.supportedLocalesByIso[option.value].iso]"
                                class="text-[18px]"
                            />

                            {{ option.text }}
                        </div>
                    </AppDropdownItem>
                </AppLink>
            </div>
        </template>
    </AppDropdown>
</template>
