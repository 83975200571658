<script
    lang="ts"
    setup
>
    import { SCROLL_CONTAINER_ID } from '~/constants/app'

    const scrollContainerRef = ref<ReturnType<typeof defineComponent>>()

    const { arrivedTop } = useScroll(scrollContainerRef)

    onMounted(() => {
        scrollContainerRef.value = document.querySelector(`#${ SCROLL_CONTAINER_ID }`)
    })
</script>

<template>
    <header
        :class="[
            $style['app-header'],
            { [$style['is-sticky']]: !arrivedTop },
        ]"
    >
        <AppLink
            key="logo"
            class="app-link"
            aria-label="Home"
            :to="{ name: 'index' }"
        >
            <AppBranding
                size="100"
                color="#000"
                height="40"
                class="!min-h-[40px]"
            />
        </AppLink>

        <TheHeaderNavigation />

        <TheHeaderActions />
    </header>
</template>

<style
    lang="sass"
    module
    scoped
>
    .app-header
        @apply z-30 sticky top-0 min-h-app-header flex items-center gap-10 w-full app-content-px max-tablet:pl-4 transition-[background,_box-shadow]

        &.is-sticky
            @apply bg-[rgba(246,247,248,0.8)] shadow-[rgb(0,0,0,0.32)_0_0.5rem_1.5rem_-1.25rem]

            &:before
                @apply content-[''] z-[-1] absolute inset-0 backdrop-blur-xl
</style>
